<script setup lang="ts">
import { useApiFetch } from '../composables/use-api-fetch';
import useSWRV from 'swrv';
import SimpleList from '../components/SimpleList.vue';
import EmbeddedModal from '../components/EmbeddedModal.vue';
import { ref } from 'vue';
import CreateAccountForm from '../components/CreateAccountForm.vue';
import AccountSync from '../components/AccountSync.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import CopyButton from '../components/CopyButton.vue';

const { apiFetch } = useApiFetch();
const { data, mutate } = useSWRV<any[]>('/api/accounts', apiFetch);

const creating = ref(false);
const handleCreate = () => {
  creating.value = false;
  mutate();
};
const handleAction = (action: string, account: any) => {
  switch (action) {
    case 'delete':
      if (confirm(`Do you really want to delete account "${account.name}"?`)) {
        apiFetch(`/api/accounts/${account.id}`, { method: 'DELETE' }).then(() =>
          mutate()
        );
      }
      break;
    default:
      break;
  }
};
</script>

<template>
  <div class="flex justify-between">
    Accounts:
    <button @click="creating = true">
      <FontAwesomeIcon :icon="faCirclePlus" />
    </button>
  </div>
  <SimpleList
    :data="data"
    :columns="{ id: 'ID', name: 'Name', domains: 'Domains' }"
    :actions="{ delete: 'Delete' }"
    @action="handleAction"
  >
    <template #id="{ data }">
      <CopyButton :text="data.id" />
    </template>
    <template #domains="{ data }">
      {{ data.associatedDomains?.join(', ') }}
    </template>
  </SimpleList>
  <div v-show="false">
    <AccountSync class="m-2" />
  </div>
  <EmbeddedModal :open="creating" @closing="creating = false">
    <div class="m-5">
      <CreateAccountForm @saved="handleCreate" />
    </div>
  </EmbeddedModal>
</template>

<style scoped lang="scss"></style>
