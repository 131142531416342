<script setup lang="ts">
import { useApiFetch } from '../composables/use-api-fetch';

const emit = defineEmits<{
  (e: 'saved', result: any): void;
}>();

const { apiFetch } = useApiFetch();

const handleSubmit = async (formData: any) => {
  const result = await apiFetch('/api/accounts', {
    method: 'POST',
    body: JSON.stringify(formData),
  });
  emit('saved', result);
};
</script>

<template>
  <FormKit
    type="form"
    @submit="handleSubmit"
    :submit-attrs="{ wrapperClass: 'mt-4', inputClass: 'underline' }"
  >
    <FormKit type="text" name="name" label="Name" validation="required" />
    <FormKit
      type="list"
      name="associatedDomains"
      dynamic
      :value="[]"
      #default="{ items, node, value }"
    >
      <div class="flex mt-2" v-for="(item, index) in items">
        <FormKit
          :key="item"
          :index="index"
          placeholder="Associated Domain"
          suffix-icon="trash"
          @suffix-icon-click="
            () => node.input(value?.filter((_, i) => i !== index))
          "
          :sections-schema="{
            suffixIcon: {
              $el: 'button',
            },
          }"
        />
      </div>
      <FormKit type="button" @click="() => node.input(value?.concat(''))">
        + Add a domain
      </FormKit>
    </FormKit>
  </FormKit>
</template>

<style scoped lang="scss"></style>
